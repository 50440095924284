import React from 'react';
import './footer.css';
import Logoquinto2 from "../../assets/iconos/LogoQI-Blanco.svg";
import { BsInstagram, BsLinkedin } from "react-icons/bs";

function Footer() {
  return (   
    <footer class="container-footer" id="Contacto">
      <div className="container">
        <div class="row align-footer">
          <div className="col-lg-4 col-6">
            <img alt="Logo Quinto Impacto" src={Logoquinto2} className='logoFooter img-fluid'/>
          </div>
          <div className="col-lg-3 col-6">
            <div  className="social-icon">
              <a href="https://www.instagram.com/quinto_impacto/" target="_blank" rel="noopener noreferrer"><BsInstagram /></a>
              <a href="https://www.linkedin.com/company/quintoimpactob/mycompany/" target="_blank" rel="noopener noreferrer"><BsLinkedin /></a>
            </div>
          </div>
          <div className="col-lg-5 container-legal">
            <p>&copy; 2023 Quinto Impacto | Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer  