import React from 'react';
import {Container} from 'react-bootstrap';
import './certificado.css';
import Iso from "../../assets/imagenes/iso.png";
import pdf from "../../assets/documentos/Certificado_ISO_9001_QI.pdf";

function Certificado() {
  return (
    <Container className='certificado'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='boxRecursos'>
            <div class="imgRecursos">
              <img alt="ISO 9001:2008" src={Iso} className='img-fluid'/>
            </div>
            <div class="contRecursos">
              <h3>Certificación ISO 9001</h3>
              <p>Implementando procesos de análisis, diseño y desarrollo, buscamos 
              asegurar la calidad en cada uno de los productos que llevamos a cabo.</p>
              <a href={pdf} target="_blank" rel="noopener noreferrer"  download="Certificado_ISO_9001_QI.pdf">Ver Certificado</a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Certificado;