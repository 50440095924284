
import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Home from '../pages/home/Home';
import { Provider } from "react-redux";
import { store } from "../store/store";

 const MainRoutes = () => {
   
  return (
    <>
        <Provider store={store}>
            <Router>
            
                <Routes>

                    <Route exact path="/" element={<Home />} />

                </Routes>
            
            </Router>
        </Provider>
    </>
    )
};
export default MainRoutes;